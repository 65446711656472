/* import { Switch } from '@headlessui/react';*/
import { Suspense, lazy } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "../src/utils/ScrollToTop";
import "./App.css";
import Barrier from "./common/components/battery/Battery";
import DataSecurityPage from "./common/components/dsgvo/DataSecurityPage";
import GeneralTerms from "./common/components/generelTerms/GeneralTerms";
import Imprint from "./common/components/imprint/Imprint";
import DefaultLayout from "./common/components/layouts/defaultLayout/DefaultLayout";
import PaymentShipping from "./common/components/paymentShipping/PaymenShipping";
import Revocation from "./common/components/revocation/Revocation";
import AboutPage from "./shop/components/about/AboutPage";
import FilteredPaginatedProductGridJewelry from "./shop/components/paginated/FilteredPaginatedProductGridJewelry";
import FilteredPaginatedProductGridTS from "./shop/components/paginated/FilteredPaginatedProductGridTS";
import ContactPage from "./shop/containers/contactPage/ContactPage";
import EmailVerificationPage from "./shop/containers/emailVerificationPage/EmailVerificationPage";
import HomePage from "./shop/containers/homePage/HomePage";
import LoginPage from "./shop/containers/loginPage/LoginPage";
import ProductDetail from "./shop/containers/productDetail/ProductDetail";
import SignupPage from "./shop/containers/signupPage/SignupPage";
import UserOverviewPage from "./shop/containers/userOverviewPage/UserOverviewPage";
import UserProfilePage from "./shop/containers/userProfilePage/userProfilePage";
import UserSettingsPage from "./shop/containers/userSettingsPage/userSettingsPage";
import UserShoppingCart from "./shop/containers/userShoppingCart/userShoppingCart";
import "./main.css";
import { CartProvider } from "./utils/CartContext";
import CheckoutConfirmation from "./shop/containers/checkout/CheckoutConfirmation";
import FilteredPaginatedProductGridWatch from "./shop/components/paginated/FilteredPaginatedProductGridWatch";
import ResetPasswordPage from "./shop/containers/resetPasswordPage/ResetPasswordPage";
import { UserProvider } from "./utils/UserContext";
import { ProtectedRoute } from "./utils/auth";
import EnterMailPage from "./shop/containers/resetPasswordPage/EnterMailPage";
const CheckoutPage = lazy(
  () => import("./shop/containers/checkout/CheckoutPage"),
);
const CheckoutPayment = lazy(
  () => import("./shop/containers/checkout/CheckoutPayment"),
);

export function formatNumberGermanStyle(num: number): string {
  return num.toLocaleString("de-DE");
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <PageTitleHelmet />
      <CartProvider>
        <UserProvider>
          <Suspense
            fallback={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          >
            <Routes>
              <Route path="/*" element={<DefaultLayout />}>
                <Route index element={<HomePage />} />
                <Route path="imprint" element={<Imprint />} />
                <Route path="datenschutz" element={<DataSecurityPage />} />
                <Route path="general-terms" element={<GeneralTerms />} />
                <Route path="battery" element={<Barrier />} />
                <Route path="payment&shipping" element={<PaymentShipping />} />
                <Route path="revocation" element={<Revocation />} />
                <Route path="wer-wir-sind" element={<AboutPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route
                  path="verify-email"
                  element={
                    <ProtectedRoute>
                      <EmailVerificationPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="UHREN"
                  element={<FilteredPaginatedProductGridWatch />}
                />
                <Route
                  path="SCHMUCK"
                  element={<FilteredPaginatedProductGridJewelry />}
                />
                <Route
                  path="THOMAS-SABO"
                  element={<FilteredPaginatedProductGridTS />}
                />
                <Route
                  path=":productLine/:articleId"
                  element={<ProductDetail />}
                />
                <Route
                  path="Warenkorb"
                  element={
                    <UserShoppingCart />
                  }
                />
                <Route
                  path="checkout"
                  element={
                    <CheckoutPage />
                  }
                />
                <Route
                  path="checkout-bezahlung"
                  element={
                    <CheckoutPayment />
                  }
                />
                <Route
                  path="checkout-bestaetigung"
                  element={
                    <CheckoutConfirmation />
                  }
                />
                <Route
                  path="user"
                  element={
                    <ProtectedRoute>
                      <UserOverviewPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="user/Bestellungen"
                  element={
                    <ProtectedRoute>
                      <UserProfilePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="user/settings"
                  element={
                    <ProtectedRoute>
                      <UserSettingsPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/sign-in" element={<LoginPage />} />
              <Route path="/sign-up" element={<SignupPage />} />
              <Route path="/forgot-email" element={<EnterMailPage />} />
              <Route
                path="/password-reset/:token"
                element={<ResetPasswordPage />}
              />
            </Routes>
          </Suspense>
        </UserProvider>
      </CartProvider>
    </Router>
  );
}

function PageTitleHelmet() {
  const location = useLocation();

  // Ein einfacher Weg, um den Seitentitel basierend auf dem Pfad zu setzen
  const pageTitle =
    {
      "/": "Juwelier Bucher - Seit 1932",
      "/about-us/wer-wir-sind": "Wer wir sind | Juwelier Bucher",
      "/contact/contactForm": "Kontakt | Juwelier Bucher",
      "/service/payment&shipping": "Zahlung & Versand | Juwelier Bucher",
      "/service/revocation": "Widerrufsbelehrung | Juwelier Bucher",
      "/service/battery": "Batterierücknahme | Juwelier Bucher",
      "/legal-information/general-terms":
        "AGB & Kundeninformationen | Juwelier Bucher",
      "/legal-information/datenschutz":
        "Datenschutz & Sicherheit | Juwelier Bucher",
      "/legal-information/imprint": "Impressum | Juwelier Bucher",
      "/user": "Kontoübersicht | Juwelier Bucher",
      "/user/profile": "Account | Juwelier Bucher",
      "/user/settings": "Einstellungen | Juwelier Bucher",
      "/Warenkorb": "Warenkorb | Juwelier Bucher",
      "/registrieren": "Registrierung | Juwelier Bucher",
      "/UHREN": "Uhren | Juwelier Bucher",
      "/SCHMUCK": "Schmuck | Juwelier Bucher",
      /* ADMIN PANEL */
      "/admin": "Admin | Juwelier Bucher",
    }[location.pathname] || "Juwelier Bucher";

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    </HelmetProvider>
  );
}

export default App;
