import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";
import { CartContext } from "../../../utils/CartContext";
import "./CartComponent.css";

interface Product {
  id: number;
  productName: string;
  price: number;
  mainImage: string;
  productLine: string;
  articleId: string;
}

interface CartItemProps {
  item: {
    product: Product;
    quantity: number;
  };
  isDbCart: boolean;
  closeCart: () => void;
  removeFromCart: (productId: number) => void;
  modifyDecrementCart: (productId: number) => void;
  modifyIncrementCart: (productId: number) => void;
}

const CartComponent = () => {
  const {
    cart,
    cartDB,
    removeFromCart,
    isCartOpen,
    closeCart,
    calculateTotalPrice,
    calculateTotalPriceDB,
    removeOneFromCart,
    addOneToCart,
    removeFromDatabaseCart,
    modifyDecrementCart,
    modifyIncrementCart,
    loadCart
  } = useContext(CartContext);

  useEffect(() => {
    loadCart()
  }, [cartDB])

  const isLoggedIn = localStorage.getItem("loggedInUser");

  if (!isCartOpen) return null;

  const renderCartItems = (items: any[], isDbCart: boolean) =>
    items.length === 0 ? (
      <p className="mt-2">Der Warenkorb ist leer.</p>
    ) : (
      <ul className="flex flex-col gap-8 overflow-hidden">
        {items.map((item, index) => (
          <CartItem
            key={index}
            item={item}
            isDbCart={isDbCart}
            closeCart={closeCart}
            removeFromCart={isDbCart ? removeFromDatabaseCart : removeFromCart}
            modifyDecrementCart={
              isDbCart ? modifyDecrementCart : removeOneFromCart
            }
            modifyIncrementCart={isDbCart ? modifyIncrementCart : addOneToCart}
          />
        ))}
        <div className="flex items-start justify-between">
          <h1 className="mb-6 mt-10 text-xl">Zwischensumme</h1>
          {calculateTotalPrice() > 0 && (
            <h1 className="mb-6 mt-10 text-xl font-bold">
              {formatPrice(
                isDbCart
                  ? calculateTotalPriceDB().toFixed(2)
                  : calculateTotalPrice().toFixed(2),
              )}
              &nbsp;€
            </h1>
          )}
        </div>
      </ul>
    );
  return (
    <div className="modal-backdrop open" onClick={closeCart}>
      <div
        className="cart-modal open min-h-screen overflow-scroll lg:min-w-[500px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between">
          <CartIcon />
          <button
            onClick={closeCart}
            className="rounded-lg border border-gray-600 px-2 py-1 hover:bg-gray-600 hover:text-white"
          >
            &#10006;
          </button>
        </div>
        <h2 className="mt-6 text-3xl font-bold text-gray-900">Warenkorb</h2>
        {isLoggedIn
          ? renderCartItems(cartDB.items, true)
          : renderCartItems(cart.items, false)}
        <div className="mt-6">
          <Link
            to={"/Warenkorb"}
            onClick={closeCart}
            className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-600"
          >
            Zur Warenkorbseite
          </Link>
        </div>
        <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
          <p className="flex gap-2">
            oder
            <Link
              to={"/UHREN"}
              onClick={closeCart}
              type="button"
              className="flex gap-1 font-medium text-blue-500 hover:text-blue-600"
            >
              weiter einkaufen
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const CartItem: React.FC<CartItemProps> = ({
  item,
  isDbCart,
  closeCart,
  removeFromCart,
  modifyDecrementCart,
  modifyIncrementCart,
}) => (
  <div className="cartItem">
    <hr />
    <li className="my-4 flex flex-row items-center justify-between gap-2">
      <div className="h-auto w-auto">
        {item.product.mainImage && (
          <img
            width={120}
            height={120}
            src={`data:image/jpeg;base64,${item.product.mainImage}`}
            className="mx-auto h-40 w-40 object-contain xl:h-full xl:w-full"
            alt={item.product.productName}
          />
        )}
      </div>
      <div className="flex min-h-[130px] w-full flex-col items-start justify-between">
        <div className="flex w-full items-start justify-between">
          <Link
            onClick={closeCart}
            to={`/${item.product.productLine}/${item.product.articleId}`}
          >
            <p className="text-base font-medium hover:text-blue-800">
              {item.product.productName}
            </p>
          </Link>
          <p className="text-xl font-bold">
            {formatPrice(item.product.price.toString())}&nbsp;€
          </p>
        </div>
        <div className="flex w-full flex-row items-center justify-between">
          <QuantityModifier
            quantity={item.quantity}
            decrement={() => modifyDecrementCart(item.product.id)}
            increment={() => modifyIncrementCart(item.product.id)}
          />
          <button
            onClick={() => removeFromCart(item.product.id)}
            className="flex items-center gap-2 rounded-lg bg-gray-100 px-2 py-2 hover:bg-gray-300"
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    </li>
  </div>
);

const QuantityModifier: React.FC<{
  quantity: number;
  decrement: () => void;
  increment: () => void;
}> = ({ quantity, decrement, increment }) => (
  <div className="quantity-holder">
    <div className="relative flex max-w-[8rem] items-center">
      <button
        type="button"
        className="flex h-6 items-center rounded-s-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
        onClick={decrement}
      >
        <DecrementIcon />
      </button>
      <h1 className="text-md flex h-6 w-11 items-center justify-center border-x-0 border-gray-300 bg-gray-50 py-2.5 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500">
        {quantity}
      </h1>
      <button
        type="button"
        className="flex h-6 items-center rounded-e-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
        onClick={increment}
      >
        <IncrementIcon />
      </button>
    </div>
  </div>
);

const CartIcon = () => (
  <svg
    width="46"
    height="40"
    viewBox="0 0 46 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_672_9)">
      <path
        d="M9.2 0.788086H36.8L46 13.7881H0L9.2 0.788086Z"
        stroke="#121212"
      />
      <path d="M0 13.7881H46L23 38.7881L0 13.7881Z" stroke="#121212" />
      <path d="M12 0.788086L7.50016 13.7881L23.0002 38.2881" stroke="#121212" />
      <path
        d="M33.5 0.788086L38.0001 13.7881L23.4921 37.8795"
        stroke="#121212"
      />
      <path d="M27 0.788086L28.9999 13.7887L23.4988 37.8235" stroke="#121212" />
      <path d="M23 38.2881L17.4999 13.788L19 0.788087" stroke="#121212" />
    </g>
    <defs>
      <clipPath id="clip0_672_9">
        <rect width="46" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DecrementIcon = () => (
  <svg
    className="h-3 w-3 text-gray-900 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 2"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1h16"
    />
  </svg>
);

const IncrementIcon = () => (
  <svg
    className="h-3 w-3 text-gray-900 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 1v16M1 9h16"
    />
  </svg>
);

const TrashIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_796_2)">
      <path
        d="M22.6562 1.56251H16.7969L16.3379 0.649423C16.2407 0.454215 16.0909 0.29001 15.9054 0.175281C15.72 0.0605526 15.5062 -0.00014785 15.2881 8.5609e-06H9.70703C9.48944 -0.000827891 9.27602 0.0596463 9.09121 0.174503C8.90641 0.289359 8.75769 0.453951 8.66211 0.649423L8.20312 1.56251H2.34375C2.13655 1.56251 1.93784 1.64482 1.79132 1.79133C1.64481 1.93784 1.5625 2.13656 1.5625 2.34376L1.5625 3.90626C1.5625 4.11346 1.64481 4.31217 1.79132 4.45869C1.93784 4.6052 2.13655 4.68751 2.34375 4.68751H22.6562C22.8635 4.68751 23.0622 4.6052 23.2087 4.45869C23.3552 4.31217 23.4375 4.11346 23.4375 3.90626V2.34376C23.4375 2.13656 23.3552 1.93784 23.2087 1.79133C23.0622 1.64482 22.8635 1.56251 22.6562 1.56251ZM4.16016 22.8027C4.19742 23.3978 4.46004 23.9562 4.89456 24.3644C5.32908 24.7727 5.90283 24.9999 6.49902 25H18.501C19.0972 24.9999 19.6709 24.7727 20.1054 24.3644C20.54 23.9562 20.8026 23.3978 20.8398 22.8027L21.875 6.25001H3.125L4.16016 22.8027Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0_796_2">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CartComponent;
