import { FunctionComponent, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Giro from "../../../assets/icons/giropay.png";
import Kontaktlos from "../../../assets/icons/kontaktlos.png";
import Master from "../../../assets/icons/mastercard.png";
import PayPal from "../../../assets/icons/paypal.png";
import "./Footer.css";
interface FooterProps {
  // Definiere die Prop-Typen hier
}

function openInNewWindow(url: string): boolean {
  window.open(
    url,
    "newWindow",
    "width=1200,height=800,scrollbars=yes,resizable=yes",
  );
  return false;
}

const Footer: FunctionComponent<FooterProps> = () => {
  const currentYear = new Date().getFullYear();
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);

  async function getLastUpdate() {
    try {
      const response = await fetch("api/aeb/last-update");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const lastUpdate = new Date(data.lastUpdate);

      // Datum formatieren (z.B. in deutschem Format)
      const formattedDate = lastUpdate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      return formattedDate;
    } catch (error) {
      console.error("Error fetching last update:", error);
      return null;
    }
  }

  useEffect(() => {
    async function fetchLastUpdate() {
      const formattedDate = await getLastUpdate();
      setLastUpdate(formattedDate);
    }
    fetchLastUpdate();
  }, []);

  return (
    <section className="g-footer">
      <div className="g-footer__content-container">
        <div className="g-footer__navGrid">
          <ul className="g-footer__navGrid__ul">
            <h3 className="g-footer__navGrid__ul__heading">Über Uns</h3>
            <li className="g-footer__navGrid__ul__item">
              <Link
                to={"/wer-wir-sind"}
                className="g-footer__navGrid--primaryLink"
              >
                <span className="g-footer__navGrid__ul__item__label">
                  Wer wir sind
                </span>
              </Link>
            </li>
          </ul>
          <ul className="g-footer__navGrid__ul">
            <h3 className="g-footer__navGrid__ul__heading">
              Rund um den Einkauf
            </h3>
            <li className="g-footer__navGrid__ul__item">
              <Link
                to={"/payment&shipping"}
                className="g-footer__navGrid--primaryLink"
              >
                <span className="g-footer__navGrid__ul__item__label">
                  Zahlung & Versand
                </span>
              </Link>
            </li>
            <li className="g-footer__navGrid__ul__item">
              <Link to={"/battery"} className="g-footer__navGrid--primaryLink">
                <span className="g-footer__navGrid__ul__item__label">
                  Batterierücknahme
                </span>
              </Link>
            </li>
            <li className="g-footer__navGrid__ul__item">
              <Link
                to={"/revocation"}
                className="g-footer__navGrid--primaryLink"
              >
                <span className="g-footer__navGrid__ul__item__label">
                  Widerrufsbelehrung
                </span>
              </Link>
            </li>
          </ul>
          <ul className="g-footer__navGrid__ul">
            <h3 className="g-footer__navGrid__ul__heading">
              Rechtsinformationen
            </h3>
            <li className="g-footer__navGrid__ul__item">
              <Link to={"/imprint"} className="g-footer__navGrid--primaryLink">
                <span className="g-footer__navGrid__ul__item__label">
                  Impressum
                </span>
              </Link>
            </li>
            <li className="g-footer__navGrid__ul__item">
              <Link
                to={"/datenschutz"}
                className="g-footer__navGrid--primaryLink"
              >
                <span className="g-footer__navGrid__ul__item__label">
                  Datenschutz & Sicherheit
                </span>
              </Link>
            </li>
            <li className="g-footer__navGrid__ul__item">
              <Link
                to={"/general-terms"}
                className="g-footer__navGrid--primaryLink"
              >
                <span className="g-footer__navGrid__ul__item__label">
                  AGB & Kundeninformationen
                </span>
              </Link>
            </li>
          </ul>
          <ul className="g-footer__navGrid__ul">
            <h3 className="g-footer__navGrid__ul__heading">Kontakt</h3>
            <li className="g-footer__navGrid__ul__item">
              <Link to={"/contact"} className="g-footer__navGrid--primaryLink">
                <span className="g-footer__navGrid__ul__item__label">
                  Kontakt
                </span>
              </Link>
            </li>
          </ul>
          <ul className="g-footer__navGrid__ul">
            <h3 className="g-footer__navGrid__ul__heading">Kundenkonto</h3>
            <li className="g-footer__navGrid__ul__item">
              <Link to={"/sign-in"} className="g-footer__navGrid--primaryLink">
                <span className="g-footer__navGrid__ul__item__label">
                  Login
                </span>
              </Link>
            </li>
            <li className="g-footer__navGrid__ul__item">
              <Link to={"/sign-up"} className="g-footer__navGrid--primaryLink">
                <span className="g-footer__navGrid__ul__item__label">
                  Registrieren
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="g-footer__navGrid__horizSep"></div>
        <p className="">
          * Preis inkl. 19% MwSt., zzgl. Versandkosten. Angebote gültig, solange der Vorrat reicht. Zwischenverkauf vorbehalten. Stattpreise sind bisherige Verkaufspreise oder unverbindliche Preisempfehlungen des Herstellers. Bei allen Waren aus unserem Shop bestehen gesetzliche Gewährleistungsrechte. Technische Änderungen und Irrtümer vorbehalten.
          <br />
          <br />
          Letzte Datenaktualisierung:{" "}
          {lastUpdate ? lastUpdate : "Aktuell keine Daten vorhanden"}
        </p>
        <div className="g-footer__navGrid__horizSep"></div>
        <div className="g-footer__cs__info">
          <ul className="g-footer__cs__info__ohGrid__ul">
            <h3 className="g-footer__cs__info__ohGrid__ul__heading">
              Öffnungszeiten
            </h3>
            <div className="flex items-start justify-start gap-6">
              <li className="g-footer__cs__info__ohGrid__ul__item">
                <p className="mb-2 text-base">Reichenbach</p>
                <span className="g-footer__cs__info__ohGrid__ul__item__label">
                  Montag-Freitag: 09:00 bis 18:00
                </span>
                <br />
                <span className="g-footer__cs__info__ohGrid__ul__item__label">
                  Samstag: 09:00 bis 13:00
                </span>
                <br />
                <span className="g-footer__cs__info__ohGrid__ul__item__label">
                  Sonntag: geschlossen
                </span>
              </li>
              <li className="g-footer__cs__info__ohGrid__ul__item">
                <p className="mb-2 text-base">Plauen</p>
                <span className="g-footer__cs__info__ohGrid__ul__item__label">
                  Montag-Samstag: 09:30 bis 19:00
                </span>
                <br />
                <span className="g-footer__cs__info__ohGrid__ul__item__label">
                  Sonntag: geschlossen
                </span>
              </li>
            </div>
          </ul>
          <ul className="g-footer__cs__info__ohGrid__ul">
            <h3 className="g-footer__cs__info__ohGrid__ul__heading">
              Bezahlmethoden
            </h3>
            <li className="g-footer__cs__info__ohGrid__ul__item">
              <div className="g-footer__cs__info__ohGrid__ul__item__payGrid">
                {/* <a
                  href="https://pay.amazon.de/"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://pay.amazon.de/");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={Amazon} width={65} height={45} alt="" />
                  </div>
                </a>
                <a
                  href="https://pay.google.com/intl/de_de/about/"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://pay.google.com/intl/de_de/about/");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={Google} width={65} height={45} alt="" />
                  </div>
                </a>
                <a
                  href="https://www.apple.com/de/apple-pay/"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://www.apple.com/de/apple-pay/");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={Apple} width={65} height={45} alt="" />
                  </div>
                </a>
                <a
                  href="https://www.klarna.com/de/"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://www.klarna.com/de/");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={Klarna} width={65} height={45} alt="" />
                  </div>
                </a> */}
                <a
                  href="https://www.mastercard.de/de-de.html"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://www.mastercard.de/de-de.html");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={Master} width={65} height={45} alt="" />
                  </div>
                </a>
                <a
                  href="https://www.paypal.com/de/home"
                  className="g-footer__invisLink"
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewWindow("https://www.paypal.com/de/home");
                  }}
                >
                  <div id="amazonPay__spriteIcon--primary">
                    <img src={PayPal} width={65} height={45} alt="" />
                  </div>
                </a>
                {/* <img src={Nachnahme} width={65} height={45} alt="" />
                <img src={Vorkasse} width={65} height={45} alt="" /> */}
                <img src={Kontaktlos} width={65} height={45} alt="" />
                <img src={Giro} width={65} height={45} alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div className="g-footer__copyright">
          <span className="g-footer__copyright__content">
            Copyright &copy; {currentYear} Juwelier Bucher. Alle Rechte
            vorbehalten. Powered by{" "}
            <a
              className="g-footer__nodiaTechLink--primary"
              href="https://nodiatech.de/"
              onClick={(e) => {
                e.preventDefault();
                openInNewWindow("https://nodiatech.de/");
              }}
            >
              NodiaTech
            </a>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer;
