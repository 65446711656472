import { useContext, useState } from "react";

import { Link } from "react-router-dom";

import { CartContext } from "../../../utils/CartContext";
//import "./userShoppingCart.css";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";

function UserShoppingCart() {
  const {
    cart,
    cartDB,
    removeFromCart,
    calculateTotalPrice,
    calculateTotalPriceDB,
    removeOneFromCart,
    addOneToCart,
    removeFromDatabaseCart,
    modifyDecrementCart,
    modifyIncrementCart,
  } = useContext(CartContext);
  const isLoggedIn = localStorage.getItem("loggedInUser");
  const [isLoading, setIsLoading] = useState(false);

  function truncateAdditionalInfo(text: string) {
    const maxLength = 70;
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  const renderCartDBItems = () =>
    cartDB.items.length === 0 ? (
      <div className="xl:max-w-1/2 mx-auto mb-[85px] flex w-full flex-col items-center justify-center md:max-w-[80%]">
        <p className="mt-6 text-3xl font-semibold">
          Es liegen keine Artikel im Warenkorb.
        </p>
        <Link
          to={"/UHREN"}
          className="text-xl-blue-500 mt-8 rounded-lg px-4 py-2 text-gray-50"
        >
          Weiter einkaufen
        </Link>
      </div>
    ) : (
      <div className="mx-auto flex h-full w-full max-w-[1280px] flex-col items-start justify-center gap-6 2xl:flex-row">
        <div className="flex w-full flex-col items-start justify-start">
          <p className="mt-6 text-3xl font-semibold">Warenkorb</p>
          <ul className="mt-6 rounded-lg bg-white px-4 shadow-md">
            {cartDB.items.map((item, index) => (
              <div key={index} className="cartItem">
                <hr />
                <li className="my-8 flex flex-row items-end justify-between">
                  <div className="flex flex-row items-start justify-center gap-4">
                    <div className="h-52 w-52">
                      {item.product.mainImage && (
                        <img
                          width={200}
                          height={200}
                          src={`data:image/jpeg;base64,${item.product.mainImage}`}
                          alt={item.product.productName}
                        />
                      )}
                    </div>
                    <div className="flex min-h-[200px] flex-col justify-between">
                      <div className="flex flex-col">
                        <Link
                          to={`../${item.product.productLine}/${item.product.articleId}`}
                        >
                          <p className="max-w-[50ch] text-xl font-medium hover:text-blue-800">
                            {item.product.productName}
                          </p>
                        </Link>
                        <p className="w-[70ch] text-lg font-medium text-gray-500">
                          {truncateAdditionalInfo(item.product.additionalInfo)}
                        </p>
                      </div>
                      <div className="flex flex-row gap-8">
                        <div className="relative flex max-w-[8rem] items-center">
                          <button
                            type="button"
                            id="decrement-button"
                            data-input-counter-decrement="quantity-input"
                            className="flex h-6 items-center rounded-s-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                            onClick={() => modifyDecrementCart(item.product.id)}
                          >
                            <svg
                              className="h-3 w-3 text-gray-900 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 2"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h16"
                              />
                            </svg>
                          </button>
                          <h1 className="text-md flex h-6 w-11 items-center justify-center border-x-0 border-gray-300 bg-gray-50 py-2.5 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500">
                            {item.quantity}
                          </h1>
                          <button
                            type="button"
                            id="increment-button"
                            data-input-counter-increment="quantity-input"
                            className="flex h-6 items-center rounded-e-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                            onClick={() => modifyIncrementCart(item.product.id)}
                          >
                            <svg
                              className="h-3 w-3 text-gray-900 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 18"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 1v16M1 9h16"
                              />
                            </svg>
                          </button>
                        </div>
                        <button
                          onClick={() =>
                            removeFromDatabaseCart(item.product.id)
                          }
                          className="flex items-center gap-2 rounded-lg bg-gray-100 px-2 py-2 hover:bg-gray-300"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_796_2)">
                              <path
                                d="M22.6562 1.56251H16.7969L16.3379 0.649423C16.2407 0.454215 16.0909 0.29001 15.9054 0.175281C15.72 0.0605526 15.5062 -0.00014785 15.2881 8.5609e-06H9.70703C9.48944 -0.000827891 9.27602 0.0596463 9.09121 0.174503C8.90641 0.289359 8.75769 0.453951 8.66211 0.649423L8.20312 1.56251H2.34375C2.13655 1.56251 1.93784 1.64482 1.79132 1.79133C1.64481 1.93784 1.5625 2.13656 1.5625 2.34376L1.5625 3.90626C1.5625 4.11346 1.64481 4.31217 1.79132 4.45869C1.93784 4.6052 2.13655 4.68751 2.34375 4.68751H22.6562C22.8635 4.68751 23.0622 4.6052 23.2087 4.45869C23.3552 4.31217 23.4375 4.11346 23.4375 3.90626V2.34376C23.4375 2.13656 23.3552 1.93784 23.2087 1.79133C23.0622 1.64482 22.8635 1.56251 22.6562 1.56251ZM4.16016 22.8027C4.19742 23.3978 4.46004 23.9562 4.89456 24.3644C5.32908 24.7727 5.90283 24.9999 6.49902 25H18.501C19.0972 24.9999 19.6709 24.7727 20.1054 24.3644C20.54 23.9562 20.8026 23.3978 20.8398 22.8027L21.875 6.25001H3.125L4.16016 22.8027Z"
                                fill="#121212"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_796_2">
                                <rect width="25" height="25" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-2xl font-bold">
                    {formatPrice(item.product.price.toString())}&nbsp;€
                  </p>
                </li>
              </div>
            ))}
          </ul>
        </div>
        <div className="flex w-full flex-col items-start justify-start">
          <p className="mt-6 text-3xl font-semibold">Zahlungsinformationen</p>
          <div className="mt-6 flex w-full flex-col items-start justify-between rounded-lg bg-white">
            <div className="w-full p-6 shadow-md">
              {calculateTotalPriceDB() === 0 ? null : (
                <h1 className="mb-4 text-xl">
                  Gesamtpreis:{" "}
                  <span className="font-bold">
                    {calculateTotalPriceDB().toFixed(2)}&nbsp;€
                  </span>
                </h1>
              )}
              <Link
                to={"/checkout"}
                className="rounded-lg bg-blue-500 px-2 py-2 text-gray-200"
              >
                Zur Kasse gehen
              </Link>
            </div>
            {/* <div className="shadow-md p-6 mt-4">
            <p className="font-semibold">
            </p>
          </div> */}
          </div>
        </div>
      </div>
    );
  const renderLocalCartItems = () =>
    cart.items.length === 0 ? (
      <div className="mx-auto mb-[85px] flex w-1/2 flex-col items-center justify-center">
        <p className="mt-6 text-3xl font-semibold">
          Es liegen keine Artikel im Warenkorb.
        </p>
        <Link
          to={"/UHREN"}
          className="mt-8 rounded-lg bg-blue-500 px-4 py-2 text-xl text-gray-50"
        >
          Weiter einkaufen
        </Link>
      </div>
    ) : (
      <div className="mx-auto mb-[85px] flex w-full flex-col items-start justify-center gap-4 lg:w-1/2 lg:flex-row">
        <ul className="px-4 shadow-md">
          <p className="mt-6 text-3xl font-semibold">Warenkorb</p>
          {cart.items.map((item, index) => (
            <div key={index} className="cartItem">
              <hr />
              <li className="my-8 flex flex-col items-start justify-between lg:flex-row">
                <div className="flex flex-row items-start justify-center">
                  <div className="h-52 w-52">
                    {item.product.images && (
                      <img
                        width={200}
                        height={200}
                        src={`data:image/jpeg;base64,${item.product.mainImage}`}
                        className="mx-auto h-52 w-52 object-contain xl:h-full xl:w-full"
                        alt={item.product.productName}
                      />
                    )}
                  </div>
                  <div className="flex min-h-[200px] flex-col justify-between">
                    <div className="flex flex-col">
                      <Link
                        to={`/${item.product.productLine}/${item.product.articleId}`}
                      >
                        <p className="max-w-[50ch] text-xl font-medium hover:text-blue-800">
                          {item.product.productName}
                        </p>
                      </Link>
                      <p className="max-w-[70ch] text-lg font-medium text-gray-500">
                        {truncateAdditionalInfo(item.product.additionalInfo)}
                      </p>
                    </div>
                    <div className="flex flex-row gap-8">
                      <div className="relative flex max-w-[8rem] items-center">
                        <button
                          type="button"
                          id="decrement-button"
                          data-input-counter-decrement="quantity-input"
                          className="flex h-6 items-center rounded-s-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                          onClick={() => removeOneFromCart(item.product.id)}
                        >
                          <svg
                            className="h-3 w-3 text-gray-900 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 2"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 1h16"
                            />
                          </svg>
                        </button>
                        <h1 className="text-md flex h-6 w-11 items-center justify-center border-x-0 border-gray-300 bg-gray-50 py-2.5 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500">
                          {item.quantity}
                        </h1>
                        <button
                          type="button"
                          id="increment-button"
                          data-input-counter-increment="quantity-input"
                          className="flex h-6 items-center rounded-e-lg border border-gray-300 bg-gray-100 p-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                          onClick={() => addOneToCart(item.product.id)}
                        >
                          <svg
                            className="h-3 w-3 text-gray-900 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 1v16M1 9h16"
                            />
                          </svg>
                        </button>
                      </div>
                      <button
                        onClick={() => removeFromCart(item.product.id)}
                        className="flex items-center gap-2 rounded-lg bg-gray-100 px-2 py-2 hover:bg-gray-300"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_796_2)">
                            <path
                              d="M22.6562 1.56251H16.7969L16.3379 0.649423C16.2407 0.454215 16.0909 0.29001 15.9054 0.175281C15.72 0.0605526 15.5062 -0.00014785 15.2881 8.5609e-06H9.70703C9.48944 -0.000827891 9.27602 0.0596463 9.09121 0.174503C8.90641 0.289359 8.75769 0.453951 8.66211 0.649423L8.20312 1.56251H2.34375C2.13655 1.56251 1.93784 1.64482 1.79132 1.79133C1.64481 1.93784 1.5625 2.13656 1.5625 2.34376L1.5625 3.90626C1.5625 4.11346 1.64481 4.31217 1.79132 4.45869C1.93784 4.6052 2.13655 4.68751 2.34375 4.68751H22.6562C22.8635 4.68751 23.0622 4.6052 23.2087 4.45869C23.3552 4.31217 23.4375 4.11346 23.4375 3.90626V2.34376C23.4375 2.13656 23.3552 1.93784 23.2087 1.79133C23.0622 1.64482 22.8635 1.56251 22.6562 1.56251ZM4.16016 22.8027C4.19742 23.3978 4.46004 23.9562 4.89456 24.3644C5.32908 24.7727 5.90283 24.9999 6.49902 25H18.501C19.0972 24.9999 19.6709 24.7727 20.1054 24.3644C20.54 23.9562 20.8026 23.3978 20.8398 22.8027L21.875 6.25001H3.125L4.16016 22.8027Z"
                              fill="#121212"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_796_2">
                              <rect width="25" height="25" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <p className="text-xl font-bold">
                  {formatPrice(item.product.price.toString())}&nbsp;€
                </p>
              </li>
            </div>
          ))}
        </ul>
        <div className="flex flex-col items-start justify-between">
          <div className="p-6 shadow-md">
            {calculateTotalPrice() === 0 ? null : (
              <h1 className="mb-4 text-xl">
                Gesamtpreis:{" "}
                <span className="font-bold">
                  {formatPrice(calculateTotalPrice().toFixed(2))}&nbsp;€
                </span>
              </h1>
            )}
            <Link
              to={"/checkout"}
              className="rounded-lg bg-blue-500 px-2 py-2 text-gray-200"
            >
              Zur Kasse gehen
            </Link>
          </div>
          {/* <div className="mt-4 p-6 shadow-md">
            <h1>
              Vergessen Sie nicht per Vorkasse zu bestellen, um die 5%
              abzustauben.
            </h1>
          </div> */}
        </div>
      </div>
    );

  return (
    <>
      <div className="user-shopping-cart-container bg-gray-100 py-12">
        {isLoggedIn ? renderCartDBItems() : renderLocalCartItems()}
      </div>
    </>
  );
}
export default UserShoppingCart;
