import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";
import { AEBOrder } from "../../../utils/types";
import { CartItem } from "../../models/Cart";

function CheckoutConfirmation() {
  const isLoggedIn = localStorage.getItem("loggedInUser");
  const location = useLocation();
  const tempCart = JSON.parse(localStorage.getItem("tempConfirmation") || "null");
  const [orders, setOrders] = useState<AEBOrder[]>([]);
  const authToken = localStorage.getItem("authToken");

  async function fetchOrderHistory() {
    try {
      const response = await fetch("/api/user/get-order-history", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setOrders(data);
    } catch (error) {}
  }

  useEffect(() => {
      fetchOrderHistory();
  }, []);

  useEffect(() => {
    const previousPathname = location.pathname;

    return () => {
      if (previousPathname === "/checkout-bestaetigung") {
        localStorage.removeItem("tempConfirmation");
      }
    };
  }, [location.pathname]);

  function truncateAdditionalInfo(text: string) {
    const maxLength = 100;
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  const renderLocalCartItems = () => (
    <div className="flex flex-col items-start justify-start p-8">
      <ul>
        {orders[orders.length - orders.length]?.items.map((prod) => (
          <div key={prod.id} className="cartItem">
            <li className="my-4 flex flex-row items-center justify-between gap-2">
              <div className="h-52 w-52">
                {prod.mainBaseImage && (
                  <img
                    width={200}
                    height={200}
                    src={`data:image/jpeg;base64,${prod.mainBaseImage}`}
                    alt={prod.product.productName}
                  />
                )}
              </div>
              <div className="flex min-h-[130px] w-full flex-col items-start justify-between">
                <div className="flex w-full items-start justify-between gap-4">
                  <Link
                    to={`/${prod.product.productLine}/${prod.product.productName}`}
                  >
                    <p className="text-lg font-semibold hover:text-blue-800">
                      {prod.product.productName}
                    </p>
                    <p className="max-w-[60ch] text-base font-medium hover:text-blue-800">
                      {truncateAdditionalInfo(prod.product.saleText)}
                    </p>
                  </Link>
                  <p className="text-xl font-bold">
                    {formatPrice(prod.price.toString())}&nbsp;€
                  </p>
                </div>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );

   const renderLocalCartItemsUnauthorized = () => (
    <div className="flex flex-col items-start justify-start p-8">
      <ul>
        {tempCart && tempCart.items.map((prod: CartItem) => (
          <div key={prod.id} className="cartItem">
            <li className="my-4 flex flex-row items-center justify-between gap-2">
              <div className="h-52 w-52">
                {prod.product.mainImage && (
                  <img
                    width={200}
                    height={200}
                    src={`data:image/jpeg;base64,${prod.product.mainImage}`}
                    alt={prod.product.productName}
                  />
                )}
              </div>
              <div className="flex min-h-[130px] w-full flex-col items-start justify-between">
                <div className="flex w-full items-start justify-between gap-4">
                  <Link
                    to={`/${prod.product.productLine}/${prod.product.productName}`}
                  >
                    <p className="text-lg font-semibold hover:text-blue-800">
                      {prod.product.productName}
                    </p>
                    <p className="max-w-[60ch] text-base font-medium hover:text-blue-800">
                      {truncateAdditionalInfo(prod.product.saleText)}
                    </p>
                  </Link>
                  <p className="text-xl font-bold">
                    {formatPrice(prod.product.price.toString())}&nbsp;€
                  </p>
                </div>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );

  const progressBar = () =>
    location.pathname === "/checkout" && (
      <div className="mx-auto flex w-full flex-col items-center justify-between gap-2">
        <div className="relative mx-auto flex w-full items-center justify-evenly lg:w-1/2 xl:justify-between">
          <div className="z-20 flex flex-col items-center justify-center">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-lg font-bold text-gray-500 ">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1C5 0.447715 5.44772 0 6 0H9C9.55228 0 10 0.447715 10 1V2H14C14.5523 2 15 2.44772 15 3V6C15 6.8888 14.6131 7.68734 14 8.23608V11.5C14 12.3284 13.3284 13 12.5 13H2.5C1.67157 13 1 12.3284 1 11.5V8.2359C0.38697 7.68721 0 6.88883 0 6V3C0 2.44772 0.447716 2 1 2H5V1ZM9 1V2H6V1H9ZM1 3H5H5.5H9.5H10H14V6C14 6.654 13.6866 7.23467 13.1997 7.6004C12.8655 7.85144 12.4508 8 12 8H8V7.5C8 7.22386 7.77614 7 7.5 7C7.22386 7 7 7.22386 7 7.5V8H3C2.5493 8 2.1346 7.85133 1.80029 7.60022C1.31335 7.23446 1 6.65396 1 6V3ZM7 9H3C2.64961 9 2.31292 8.93972 2 8.82905V11.5C2 11.7761 2.22386 12 2.5 12H12.5C12.7761 12 13 11.7761 13 11.5V8.82915C12.6871 8.93978 12.3504 9 12 9H8V9.5C8 9.77614 7.77614 10 7.5 10C7.22386 10 7 9.77614 7 9.5V9Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <p className="absolute top-12 hidden lg:flex">Warenkorb</p>
          </div>
          <div className="z-20 flex flex-col items-center justify-center">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-lg font-bold text-gray-500 ">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H12V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H12C12.5523 14 13 13.5523 13 13V2C13 1.44772 12.5523 1 12 1H4.5ZM6.60355 4.89645C6.40829 4.70118 6.09171 4.70118 5.89645 4.89645C5.70118 5.09171 5.70118 5.40829 5.89645 5.60355L7.29289 7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H7.29289L5.89645 9.39645C5.70118 9.59171 5.70118 9.90829 5.89645 10.1036C6.09171 10.2988 6.40829 10.2988 6.60355 10.1036L8.85355 7.85355C9.04882 7.65829 9.04882 7.34171 8.85355 7.14645L6.60355 4.89645Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <p className="absolute top-12 hidden lg:flex">Bestellung</p>
          </div>
          <div className="z-20 flex flex-col items-center justify-center">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-lg font-bold text-gray-500 ">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H12V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H12C12.5523 14 13 13.5523 13 13V2C13 1.44772 12.5523 1 12 1H4.5ZM6.60355 4.89645C6.40829 4.70118 6.09171 4.70118 5.89645 4.89645C5.70118 5.09171 5.70118 5.40829 5.89645 5.60355L7.29289 7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H7.29289L5.89645 9.39645C5.70118 9.59171 5.70118 9.90829 5.89645 10.1036C6.09171 10.2988 6.40829 10.2988 6.60355 10.1036L8.85355 7.85355C9.04882 7.65829 9.04882 7.34171 8.85355 7.14645L6.60355 4.89645Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <p className="absolute top-12 hidden lg:flex">Bezahlung</p>
          </div>
          <div className="z-20 flex flex-col items-center justify-center">
            <span className="flex h-12 w-12 items-center justify-center gap-2 rounded-full bg-blue-300 text-lg font-bold text-blue-700">
              <svg
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 11.0001V4.00006L1 4.00006L1 11.0001H14ZM15 4.00006V11.0001C15 11.5523 14.5523 12.0001 14 12.0001H1C0.447715 12.0001 0 11.5523 0 11.0001V4.00006C0 3.44778 0.447715 3.00006 1 3.00006H14C14.5523 3.00006 15 3.44778 15 4.00006ZM2 5.25C2 5.11193 2.11193 5 2.25 5H5.75C5.88807 5 6 5.11193 6 5.25V9.75C6 9.88807 5.88807 10 5.75 10H2.25C2.11193 10 2 9.88807 2 9.75V5.25ZM7.5 7C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H7.5ZM7 9.5C7 9.22386 7.22386 9 7.5 9H12.5C12.7761 9 13 9.22386 13 9.5C13 9.77614 12.7761 10 12.5 10H7.5C7.22386 10 7 9.77614 7 9.5ZM7.5 5C7.22386 5 7 5.22386 7 5.5C7 5.77614 7.22386 6 7.5 6H11.5C11.7761 6 12 5.77614 12 5.5C12 5.22386 11.7761 5 11.5 5H7.5Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <p className="absolute top-12 hidden text-blue-500 lg:flex">
              Bestellbestätigung
            </p>
          </div>
          <div className="absolute left-0 right-0 top-6 mx-auto h-[2px] w-full bg-gray-200"></div>
        </div>
      </div>
    );

  return (
    <div className="mx-auto mt-[2.5rem] flex flex-col items-center justify-center">
      <section className="flex h-20 w-full items-start justify-start border-b-2 border-gray-200">
        {progressBar()}
      </section>
      <section className="flex w-full flex-col items-start justify-center lg:flex-row">
        <section className="flex w-full flex-col items-end justify-center p-8 lg:w-1/2">
          <div className="w-full max-w-[960px]">
            {/* <div className="flex flex-col items-center justify-center">
              <img src={logo} alt="" />
              <div className="flex flex-col items-center justify-center p-2 w-full mt-8">
                <p className="text-gray-400 mx-auto">Express-Checkout</p>
                <Link to={""}>PayPal</Link>
              </div>
            </div>
            <span className="w-full flex items-center justify-center p-12 gap-8">
              <span className="w-full h-[1px] bg-gray-300" />
              <p className="text-gray-400">ODER</p>
              <span className="w-full h-[1px] bg-gray-300" />
            </span> */}
            <div className="flex items-center justify-center p-2 lg:p-12">
              <div className="mx-auto w-full max-w-[960px]">
                <h1 className="py-2 text-left text-3xl">Bestellung</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="min-h-screen w-full border-l-2 border-gray-300 bg-gray-100 lg:w-1/2">
          {isLoggedIn ? renderLocalCartItems() : renderLocalCartItemsUnauthorized()}
        </section>
      </section>
    </div>
  );
}

export default CheckoutConfirmation;
